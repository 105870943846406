<template>
  <v-container>
    <v-card v-if="selected">
      <v-container
          fluid
          grid-list-lg
      >
        <v-layout row wrap>
          <v-flex xs12 v-for="routeDay of selectedRoute.routeDays" :key="'routeDay' + routeDay.id">
            <h2>{{routeDay.date|formatDate}}</h2>

            <div v-for="routeElement of routeDay.routeElements" :key="'routeElement' + routeElement.id">
              <v-card color="" style="margin-bottom:5px;" v-if="routeElement.collection.status == 'planned'">
                <v-card-title primary-title>
                  <div>
                    <div class="headline">{{routeElement.arrivalTime}}</div>
                    <div>{{routeElement.destinationAddress.company}}</div>
                    <div>{{routeElement.destinationAddress.address}}</div>
                    <div>{{routeElement.destinationAddress.postalcode}} {{routeElement.destinationAddress.city}}</div>
                  </div>
                </v-card-title>
                <v-card-actions>
                  <v-btn color="warning" @click="driveCustomer(routeElement)">Jetzt anfahren</v-btn>
                  <v-btn color="info" @click="callCustomer(routeElement)">Kunde anrufen</v-btn>
                  <v-btn color="success" @click="doneDelivery(routeElement.collection)">Lieferung erledigt</v-btn>
                </v-card-actions>

                <GmapMap
                    v-if="currentRouteElement && currentRouteElement.id == routeElement.id"
                    :center="{lat:+routeElement.destinationAddress.latitude, lng:+routeElement.destinationAddress.longitude}"
                    :zoom="10"
                    map-type-id="terrain"
                    style="width: 100%; height: 300px"
                >
                  <GmapMarker
                      :position="{lat:+routeElement.destinationAddress.latitude, lng:+routeElement.destinationAddress.longitude}"
                      :clickable="true"
                      :draggable="true"
                      @click="center=m.position"
                  />
                </GmapMap>
              </v-card>
            </div>
          </v-flex>
        </v-layout>
      </v-container>

    </v-card>

    <v-card v-if="!selected">
      <v-container
          fluid
          grid-list-lg
      >
        <v-layout row wrap>
          <v-flex xs12 v-for="route in routes" :key="route.id">
            <v-card color="blue-grey darken-2" class="white--text">
              <v-card-title primary-title>
                <div>
                  <div class="headline">{{route.title}}</div>
                  <span>Ab {{route.startDate|formatDate}}</span>
                </div>
              </v-card-title>
              <v-card-actions>
                <v-btn dark @click="selectRoute(route)">Jetzt fahren</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
  const axios = require('axios');

  export default {
    name: 'HelloWorld',

    data: () => ({
      selected: null,
      selectedRoute: null,
      currentRouteElement: null,
      routes: []
    }),
    methods: {
      callCustomer() {
        // start phone call
      },
      driveCustomer(routeElement) {
        this.currentRouteElement = routeElement;
      },
      doneDelivery(collection) {
        axios.patch('/delivery_collections/' +  collection.id, { status:"done"}).then(() => {
          collection.status = 'done';
        });

      },
      selectRoute(route) {

        axios.get('routes/' +  route.id).then((response) => {
          this.selectedRoute = response.data;
          this.selected = route.id;
        });

      }
    },
    mounted: function() {
      axios.defaults.headers.common['X-AUTH-TOKEN'] = '0de31f86ce7659f816cbcceb8fb700faa37626ea709859910e38d709f283501b';
      axios.defaults.baseURL = 'https://logistik.redoo.cloud/api';
      axios.defaults.headers.get['Accept'] = 'application/json';
      axios.defaults.headers.post['Accept'] = 'application/json';
      axios.defaults.headers.patch['Accept'] = 'application/json';
      axios.defaults.headers.delete['Accept'] = 'application/json';

      axios.get('routes?status=Planned').then((response) => {
        this.routes = response.data;
      });

    }
  }
</script>
